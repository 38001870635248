export let softs = [
 {
  "id": "1",
  "ini": "な",
  "name": "NAM-1975",
  "maker": "SNK",
  "releaseDate": "1990/4/26",
  "price": "13800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "2",
  "ini": "へ",
  "name": "ベースボールスターズプロフェッショナル",
  "maker": "SNK",
  "releaseDate": "1990/4/26",
  "price": "18000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "3",
  "ini": "ま",
  "name": "麻雀狂列伝 西日本編",
  "maker": "SNK",
  "releaseDate": "1990/4/26",
  "price": "15800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "4",
  "ini": "ま",
  "name": "マジシャンロード",
  "maker": "アルファ電子",
  "releaseDate": "1990/4/26",
  "price": "15800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "5",
  "ini": "と",
  "name": "トッププレイヤーズゴルフ",
  "maker": "SNK",
  "releaseDate": "1990/5/23",
  "price": "21000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "6",
  "ini": "ら",
  "name": "ライディングヒーロー",
  "maker": "SNK",
  "releaseDate": "1990/7/24",
  "price": "21000",
  "genre": "レース・3D",
  "icNo": "1"
 },
 {
  "id": "7",
  "ini": "に",
  "name": "ニンジャコンバット",
  "maker": "アルファ電子",
  "releaseDate": "1990/7/24",
  "price": "18000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "8",
  "ini": "さ",
  "name": "ザ・スーパースパイ",
  "maker": "SNK",
  "releaseDate": "1990/10/8",
  "price": "19000",
  "genre": "アクション・3D",
  "icNo": "1"
 },
 {
  "id": "9",
  "ini": "さ",
  "name": "サイバーリップ",
  "maker": "SNK",
  "releaseDate": "1990/11/7",
  "price": "18000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "10",
  "ini": "し",
  "name": "ジョイジョイキッド",
  "maker": "SNK",
  "releaseDate": "1990/11/20",
  "price": "13800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "11",
  "ini": "り",
  "name": "リーグボウリング",
  "maker": "SNK",
  "releaseDate": "1990/12/10",
  "price": "16800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "12",
  "ini": "こ",
  "name": "ゴーストパイロット",
  "maker": "SNK",
  "releaseDate": "1991/7/1",
  "price": "19800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "13",
  "ini": "せ",
  "name": "戦国伝承",
  "maker": "SNK",
  "releaseDate": "1991/7/1",
  "price": "19800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "14",
  "ini": "き",
  "name": "キング・オブ・ザ・モンスターズ",
  "maker": "SNK",
  "releaseDate": "1991/7/1",
  "price": "19800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "15",
  "ini": "ら",
  "name": "ラギ",
  "maker": "アルファ電子",
  "releaseDate": "1991/7/1",
  "price": "19800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "16",
  "ini": "え",
  "name": "ASOII LAST GUARDIAN",
  "maker": "SNK",
  "releaseDate": "1991/7/1",
  "price": "18800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "17",
  "ini": "み",
  "name": "みなさんのおかげさまです！大スゴロク大会",
  "maker": "モノリス",
  "releaseDate": "1991/7/1",
  "price": "21000",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "18",
  "ini": "は",
  "name": "バーニングファイト",
  "maker": "SNK",
  "releaseDate": "1991/8/9",
  "price": "19800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "19",
  "ini": "く",
  "name": "クイズ大捜査線 THE LAST COUNT DOWN",
  "maker": "SNK",
  "releaseDate": "1991/8/30",
  "price": "16800",
  "genre": "クイズ",
  "icNo": "1"
 },
 {
  "id": "20",
  "ini": "あ",
  "name": "あしたのジョー伝説",
  "maker": "ウェイブ",
  "releaseDate": "1991/8/30",
  "price": "19800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "21",
  "ini": "く",
  "name": "クロスソード",
  "maker": "アルファ電子",
  "releaseDate": "1991/10/1",
  "price": "21800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "22",
  "ini": "に",
  "name": "2020年スーパーベースボール",
  "maker": "SNK",
  "releaseDate": "1991/10/25",
  "price": "21800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "23",
  "ini": "え",
  "name": "8マン",
  "maker": "SNK",
  "releaseDate": "1991/11/20",
  "price": "21800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "24",
  "ini": "か",
  "name": "餓狼伝説 宿命の闘い",
  "maker": "SNK",
  "releaseDate": "1991/12/20",
  "price": "23800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "25",
  "ini": "す",
  "name": "スラッシュラリー",
  "maker": "SNK",
  "releaseDate": "1991/12/20",
  "price": "23800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "26",
  "ini": "は",
  "name": "バカ殿様麻雀漫遊記",
  "maker": "モノリス",
  "releaseDate": "1991/12/20",
  "price": "21000",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "27",
  "ini": "ろ",
  "name": "ロボアーミー",
  "maker": "SNK",
  "releaseDate": "1991/12/20",
  "price": "21800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "28",
  "ini": "ふ",
  "name": "フットボールフレンジー",
  "maker": "SNK",
  "releaseDate": "1992/2/21",
  "price": "23800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "29",
  "ini": "さ",
  "name": "サッカーブロール",
  "maker": "SNK",
  "releaseDate": "1992/3/13",
  "price": "21800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "30",
  "ini": "み",
  "name": "ミューテイション・ネイション",
  "maker": "SNK",
  "releaseDate": "1992/4/17",
  "price": "21800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "31",
  "ini": "く",
  "name": "クイズ迷探偵ネオ&ジオ",
  "maker": "SNK",
  "releaseDate": "1992/4/24",
  "price": "21800",
  "genre": "クイズ",
  "icNo": "1"
 },
 {
  "id": "32",
  "ini": "ら",
  "name": "ラストリゾート",
  "maker": "SNK",
  "releaseDate": "1992/4/24",
  "price": "22800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "33",
  "ini": "へ",
  "name": "ベースボールスターズ2",
  "maker": "SNK",
  "releaseDate": "1992/4/28",
  "price": "23800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "34",
  "ini": "に",
  "name": "ニンジャコマンドー",
  "maker": "ADK",
  "releaseDate": "1992/5/29",
  "price": "23800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "35",
  "ini": "き",
  "name": "キング・オブ・ザ・モンスターズ2 THE NEXT THING",
  "maker": "SNK",
  "releaseDate": "1992/6/26",
  "price": "25800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "36",
  "ini": "あ",
  "name": "アンドロデュノス",
  "maker": "ビスコ",
  "releaseDate": "1992/7/17",
  "price": "23800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "37",
  "ini": "わ",
  "name": "ワールドヒーローズ",
  "maker": "アルファ電子",
  "releaseDate": "1992/9/11",
  "price": "25800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "38",
  "ini": "り",
  "name": "龍虎の拳",
  "maker": "SNK",
  "releaseDate": "1992/12/11",
  "price": "28000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "39",
  "ini": "ひ",
  "name": "ビューポイント",
  "maker": "サミー工業",
  "releaseDate": "1992/12/11",
  "price": "25000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "40",
  "ini": "と",
  "name": "得点王",
  "maker": "SNK",
  "releaseDate": "1993/2/19",
  "price": "23000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "41",
  "ini": "か",
  "name": "餓狼伝説2 新たなる闘い",
  "maker": "SNK",
  "releaseDate": "1993/3/5",
  "price": "28000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "42",
  "ini": "せ",
  "name": "戦国伝承2",
  "maker": "SNK",
  "releaseDate": "1993/4/9",
  "price": "25000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "43",
  "ini": "ふ",
  "name": "ファイヤースープレックス",
  "maker": "SNK",
  "releaseDate": "1993/4/23",
  "price": "28000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "44",
  "ini": "わ",
  "name": "ワールドヒーローズ2",
  "maker": "ADK",
  "releaseDate": "1993/6/4",
  "price": "28000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "45",
  "ini": "さ",
  "name": "サムライスピリッツ",
  "maker": "SNK",
  "releaseDate": "1993/8/11",
  "price": "28000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "46",
  "ini": "か",
  "name": "餓狼伝説スペシャル",
  "maker": "SNK",
  "releaseDate": "1993/12/22",
  "price": "28000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "47",
  "ini": "み",
  "name": "ミラクルアドベンチャー",
  "maker": "データイースト",
  "releaseDate": "1994/2/18",
  "price": "25000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "48",
  "ini": "り",
  "name": "龍虎の拳2",
  "maker": "SNK",
  "releaseDate": "1994/3/11",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "49",
  "ini": "ふ",
  "name": "フライングパワーディスク",
  "maker": "データイースト",
  "releaseDate": "1994/4/8",
  "price": "25000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "50",
  "ini": "ふ",
  "name": "ファイターズヒストリー ダイナマイト",
  "maker": "データイースト",
  "releaseDate": "1994/4/28",
  "price": "28000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "51",
  "ini": "と",
  "name": "得点王2",
  "maker": "SNK",
  "releaseDate": "1994/5/27",
  "price": "28000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "52",
  "ini": "わ",
  "name": "ワールドヒーローズ2 JET",
  "maker": "ADK",
  "releaseDate": "1994/6/10",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "53",
  "ini": "と",
  "name": "トップハンター ～ロディー&キャシー～",
  "maker": "SNK",
  "releaseDate": "1994/6/24",
  "price": "28000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "54",
  "ini": "そ",
  "name": "ソニックウィングス2",
  "maker": "ビデオシステム",
  "releaseDate": "1994/8/26",
  "price": "28000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "55",
  "ini": "つ",
  "name": "痛快GANGAN行進曲",
  "maker": "ADK",
  "releaseDate": "1994/8/26",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "56",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ'94",
  "maker": "SNK",
  "releaseDate": "1994/10/1",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "57",
  "ini": "し",
  "name": "真サムライスピリッツ 覇王丸地獄変",
  "maker": "SNK",
  "releaseDate": "1994/12/2",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "58",
  "ini": "た",
  "name": "ダンクドリーム",
  "maker": "データイースト",
  "releaseDate": "1994/12/9",
  "price": "28000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "59",
  "ini": "き",
  "name": "ギャラクシーファイト ユニバーサル・ウォーリアーズ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1995/2/25",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "60",
  "ini": "く",
  "name": "クイズ キング・オブ・ファイターズ",
  "maker": "ザウルス",
  "releaseDate": "1995/3/10",
  "price": "29800",
  "genre": "クイズ",
  "icNo": "1"
 },
 {
  "id": "61",
  "ini": "は",
  "name": "パワースパイクスⅡ",
  "maker": "ビデオシステム",
  "releaseDate": "1995/3/17",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "62",
  "ini": "た",
  "name": "ダブルドラゴン",
  "maker": "テクノスジャパン",
  "releaseDate": "1995/3/31",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "63",
  "ini": "し",
  "name": "雀神伝説 QUEST OF JONGMASTER",
  "maker": "SNK",
  "releaseDate": "1995/3/31",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "64",
  "ini": "と",
  "name": "得点王3 栄光への挑戦",
  "maker": "SNK",
  "releaseDate": "1995/4/7",
  "price": "28000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "65",
  "ini": "か",
  "name": "餓狼伝説3 遥かなる闘い",
  "maker": "SNK",
  "releaseDate": "1995/4/21",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "66",
  "ini": "は",
  "name": "パズルボブル",
  "maker": "タイトー",
  "releaseDate": "1995/5/2",
  "price": "6800",
  "genre": "パズル",
  "icNo": "2"
 },
 {
  "id": "67",
  "ini": "く",
  "name": "クロススウォードⅡ",
  "maker": "ADK",
  "releaseDate": "1995/5/2",
  "price": "5800",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "68",
  "ini": "ふ",
  "name": "風雲黙示録",
  "maker": "SNK",
  "releaseDate": "1995/5/26",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "69",
  "ini": "わ",
  "name": "ワールドヒーローズ パーフェクト",
  "maker": "ADK",
  "releaseDate": "1995/6/30",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "70",
  "ini": "て",
  "name": "天外魔境 真伝",
  "maker": "ハドソン",
  "releaseDate": "1995/7/28",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "71",
  "ini": "た",
  "name": "対戦アイドル麻雀ファイナルロマンス2",
  "maker": "ビデオシステム",
  "releaseDate": "1995/8/25",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "72",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ'95",
  "maker": "SNK",
  "releaseDate": "1995/9/1",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "73",
  "ini": "は",
  "name": "パルスター",
  "maker": "SNK",
  "releaseDate": "1995/9/29",
  "price": "32000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "74",
  "ini": "し",
  "name": "将棋の達人",
  "maker": "SNK",
  "releaseDate": "1995/10/13",
  "price": "29800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "75",
  "ini": "ち",
  "name": "超人学園ゴウカイザー",
  "maker": "テクノスジャパン",
  "releaseDate": "1995/10/20",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "76",
  "ini": "す",
  "name": "ステークスウィナー",
  "maker": "ザウルス",
  "releaseDate": "1995/10/27",
  "price": "29800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "77",
  "ini": "え",
  "name": "ADKワールド",
  "maker": "ADK",
  "releaseDate": "1995/11/10",
  "price": "6800",
  "genre": "ミニゲーム",
  "icNo": "2"
 },
 {
  "id": "78",
  "ini": "そ",
  "name": "ソニックウィングス3",
  "maker": "ビデオシステム",
  "releaseDate": "1995/11/17",
  "price": "29800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "79",
  "ini": "さ",
  "name": "サムライスピリッツ 斬紅郎無双剣",
  "maker": "SNK",
  "releaseDate": "1995/12/1",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "80",
  "ini": "ね",
  "name": "NEO･GEO CD SPECIAL",
  "maker": "SNK",
  "releaseDate": "1995/12/22",
  "price": "3800",
  "genre": "ミニゲーム",
  "icNo": "2"
 },
 {
  "id": "81",
  "ini": "ち",
  "name": "ちびまる子ちゃん まる子デラックスクイズ",
  "maker": "タカラ",
  "releaseDate": "1996/1/26",
  "price": "29800",
  "genre": "クイズ",
  "icNo": "1"
 },
 {
  "id": "82",
  "ini": "り",
  "name": "リアルバウト餓狼伝説",
  "maker": "SNK",
  "releaseDate": "1996/1/26",
  "price": "34000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "83",
  "ini": "ひ",
  "name": "ビッグトーナメントゴルフ",
  "maker": "ナスカ",
  "releaseDate": "1996/3/1",
  "price": "29800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "84",
  "ini": "お",
  "name": "押し出しジントリック",
  "maker": "ADK",
  "releaseDate": "1996/3/22",
  "price": "4800",
  "genre": "パズル",
  "icNo": "2"
 },
 {
  "id": "85",
  "ini": "ま",
  "name": "マジカルドロップ2",
  "maker": "データイースト",
  "releaseDate": "1996/4/19",
  "price": "29800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "86",
  "ini": "あ",
  "name": "ART OF FIGHTING 龍虎の拳 外伝",
  "maker": "SNK",
  "releaseDate": "1996/4/26",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "87",
  "ini": "め",
  "name": "メタルスラッグ",
  "maker": "ナスカ",
  "releaseDate": "1996/5/24",
  "price": "29800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "88",
  "ini": "お",
  "name": "オーバートップ",
  "maker": "ADK",
  "releaseDate": "1996/5/24",
  "price": "29800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "89",
  "ini": "に",
  "name": "ニンジャマスターズ 覇王忍法帖",
  "maker": "SNK",
  "releaseDate": "1996/6/28",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "90",
  "ini": "ふ",
  "name": "フットサル 5 ON 5 MINI SOCCER",
  "maker": "ザウルス",
  "releaseDate": "1996/7/19",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "91",
  "ini": "し",
  "name": "神凰拳",
  "maker": "SNK",
  "releaseDate": "1996/7/26",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "92",
  "ini": "ね",
  "name": "NEOドリフトアウト",
  "maker": "ビスコ",
  "releaseDate": "1996/7/26",
  "price": "6800",
  "genre": "レース",
  "icNo": "2"
 },
 {
  "id": "93",
  "ini": "ち",
  "name": "超鉄ブリキンガー",
  "maker": "ザウルス",
  "releaseDate": "1996/9/20",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "2"
 },
 {
  "id": "94",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ'96",
  "maker": "SNK",
  "releaseDate": "1996/9/27",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "95",
  "ini": "ふ",
  "name": "風雲スーパータッグバトル",
  "maker": "SNK",
  "releaseDate": "1996/11/8",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "96",
  "ini": "さ",
  "name": "サムライスピリッツ 天草降臨",
  "maker": "SNK",
  "releaseDate": "1996/11/29",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "97",
  "ini": "す",
  "name": "ステークスウィナー2",
  "maker": "ザウルス",
  "releaseDate": "1996/12/13",
  "price": "29800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "98",
  "ini": "と",
  "name": "得点王 炎のリベロ",
  "maker": "SNK",
  "releaseDate": "1996/12/20",
  "price": "29800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "99",
  "ini": "わ",
  "name": "わくわく7",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1996/12/27",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "100",
  "ini": "て",
  "name": "ティンクルスター スプライツ",
  "maker": "ADK",
  "releaseDate": "1997/1/31",
  "price": "29800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "101",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ'96 ネオジオコレクション",
  "maker": "SNK",
  "releaseDate": "1997/2/14",
  "price": "3800",
  "genre": "データ集",
  "icNo": "2"
 },
 {
  "id": "102",
  "ini": "り",
  "name": "リアルバウト餓狼伝説スペシャル",
  "maker": "SNK",
  "releaseDate": "1997/2/28",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "103",
  "ini": "ふ",
  "name": "ブレイカーズ",
  "maker": "ビスコ",
  "releaseDate": "1997/3/21",
  "price": "29800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "104",
  "ini": "ま",
  "name": "マジカルドロップ3",
  "maker": "データイースト",
  "releaseDate": "1997/4/25",
  "price": "29800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "105",
  "ini": "し",
  "name": "真説サムライスピリッツ 武士道烈伝",
  "maker": "SNK",
  "releaseDate": "1997/6/27",
  "price": "6800",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "106",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ'97",
  "maker": "SNK",
  "releaseDate": "1997/9/25",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "107",
  "ini": "は",
  "name": "幕末浪漫 月華の剣士",
  "maker": "SNK",
  "releaseDate": "1998/1/29",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "108",
  "ini": "ふ",
  "name": "ブレイジングスター",
  "maker": "SNK",
  "releaseDate": "1998/2/26",
  "price": "29800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "109",
  "ini": "め",
  "name": "メタルスラッグ2",
  "maker": "SNK",
  "releaseDate": "1998/4/2",
  "price": "29800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "110",
  "ini": "り",
  "name": "リアルバウト餓狼伝説2 THE NEWCOMERS",
  "maker": "SNK",
  "releaseDate": "1998/4/29",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "111",
  "ini": "し",
  "name": "ショックトルーパーズ セカンドスカッド",
  "maker": "ザウルス",
  "releaseDate": "1998/6/24",
  "price": "29800",
  "genre": "アクション・シューティング",
  "icNo": "1"
 },
 {
  "id": "112",
  "ini": "ね",
  "name": "ネオジオカップ'98 THE ROAD TO THE VICTORY",
  "maker": "SNK",
  "releaseDate": "1998/7/30",
  "price": "29800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "113",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ'98 DREAM MATCH NEVER ENDS",
  "maker": "SNK",
  "releaseDate": "1998/9/28",
  "price": "38000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "114",
  "ini": "は",
  "name": "幕末浪漫第二幕 月華の剣士",
  "maker": "SNK",
  "releaseDate": "1999/1/28",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "115",
  "ini": "め",
  "name": "メタルスラッグX",
  "maker": "SNK",
  "releaseDate": "1999/5/27",
  "price": "29800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "116",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ'99 Millennium Battle",
  "maker": "SNK",
  "releaseDate": "1999/9/23",
  "price": "38000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "117",
  "ini": "か",
  "name": "餓狼 MARK OF THE WOLVES",
  "maker": "SNK",
  "releaseDate": "2000/2/25",
  "price": "32000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "118",
  "ini": "め",
  "name": "メタルスラッグ3",
  "maker": "SNK",
  "releaseDate": "2000/6/1",
  "price": "32000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "119",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ2000",
  "maker": "SNK",
  "releaseDate": "2000/12/21",
  "price": "38000",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "120",
  "ini": "せ",
  "name": "戦国伝承2001",
  "maker": "SNK",
  "releaseDate": "2001/10/25",
  "price": "32000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "121",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ2001",
  "maker": "サン・アミューズメント",
  "releaseDate": "2002/3/14",
  "price": "39800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "122",
  "ini": "め",
  "name": "メタルスラッグ4",
  "maker": "プレイモア",
  "releaseDate": "2002/6/13",
  "price": "39800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "123",
  "ini": "れ",
  "name": "レイジ・オブ・ザ・ドラゴンズ",
  "maker": "プレイモア",
  "releaseDate": "2002/9/26",
  "price": "39800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "124",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ2002 Challenge to Ultimate Battle",
  "maker": "プレイモア",
  "releaseDate": "2002/12/19",
  "price": "39800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "125",
  "ini": "し",
  "name": "新・豪血寺一族 闘婚 Matrimelee",
  "maker": "プレイモア",
  "releaseDate": "2003/5/29",
  "price": "39800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "126",
  "ini": "え",
  "name": "SNK VS. CAPCOM SVC CHAOS",
  "maker": "SNKプレイモア",
  "releaseDate": "2003/11/13",
  "price": "39800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "127",
  "ini": "さ",
  "name": "サムライスピリッツ零",
  "maker": "SNKプレイモア",
  "releaseDate": "2003/12/11",
  "price": "39800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "128",
  "ini": "め",
  "name": "メタルスラッグ5",
  "maker": "SNKプレイモア",
  "releaseDate": "2004/2/19",
  "price": "39800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "129",
  "ini": "さ",
  "name": "ザ・キング・オブ・ファイターズ2003",
  "maker": "SNKプレイモア",
  "releaseDate": "2004/3/18",
  "price": "39800",
  "genre": "対戦格闘",
  "icNo": "1"
 },
 {
  "id": "130",
  "ini": "さ",
  "name": "サムライスピリッツ零 スペシャル",
  "maker": "SNKプレイモア",
  "releaseDate": "2004/7/15",
  "price": "39800",
  "genre": "対戦格闘",
  "icNo": "1"
 }
];